import {
  Box,
  Button,
  Container,
  Flex,
  FormControl,
  FormLabel,
  Heading,
  Input,
  InputGroup,
  InputLeftElement,
  useToast,
  Text,
  Textarea,
  useColorMode,
  VStack,
  Wrap,
  WrapItem,
} from "@chakra-ui/react"
import axios from "axios"
import { Link } from "gatsby"
import React, { useEffect, useState } from "react"
import { useForm } from "react-hook-form"
import { BsPerson } from "react-icons/bs"
import { MdEmail, MdPhone } from "react-icons/md"
import BannerBlepharo from "../components/choc/BannerBlepharo"
import BannerHyaloron from "../components/choc/BannerHyaloron"
import LogoHeadlineTeaserVeo from "../components/choc/LogoHeadlineTeaserVEO"
import QuadTeaser from "../components/choc/QuadTeaser"
import ImgTextModule from "../components/ImgTextModule"
import Layout from "../components/layout/Layout"
import TwoColumnGridBoxesAllgemein from "../components/ThreeColumnGridBoxesAllgemein"
import FadeInAnimation from "../DefinitionComponents/Animation-Components/FadeInAnimation"

const Kontakt = () => {
  const toast = useToast()
  const {
    register,
    handleSubmit,
    reset,
    formState,
    formState: { errors, isSubmitting, isSubmitted, isSubmitSuccessful },
  } = useForm()

  function onSubmit(values) {
    return new Promise(resolve => {
      setTimeout(() => {
        axios.post("https://mailer-endpoint.veo-med.com/", values)
        console.log(values)
        resolve()
      }, 1000)
    })
  }
  let { toggleColorMode, ColorMode, setColorMode } = useColorMode()
  useEffect(() => {
    setColorMode(colormodeValue)
  })
  const [viewportHeight, setViewportHeight] = useState(0)
  const [pageHeight, setPageHeight] = useState(0)
  useEffect(() => {
    setViewportHeight(window.innerHeight)
    setPageHeight(document.body.clientHeight)
    console.log("VIEWPORTHEIGHT: " + window.innerHeight)
    console.log("PAGEHEIGHT: " + document.body.clientHeight)
  }, [])
  const [colormodeValue, changeColorModeValue] = useState("light")
  return (
    <Layout
        title={"Jadeklinik | VeoMed"}
        description={"Sprechen Sie uns an. "}
    >
      <Container variant="layoutContainer" mt={0}>
        <ImgTextModule
          w="100%"
          px={{ base: 0, md: 12, lg: 4, xl: 0 }}
          src={"nummer2.mp4"}
          delay={0.9}
          duration={1}
          threshold={0.4}
          initialY={0}
          initialX={0}
          video={true}
          colStart={1}
          colEnd={3}
          uiomediaOpacity={0.8}
          bgColor="black"
        >
          <FadeInAnimation threshold={0.4} duration={1}>
            <Box p={{ base: 0, lg: 8 }} pt={32} minH="650px">
              <Box p={{ base: 0, lg: 4 }} w="100%">
                <Wrap w="100%" spacing={{ base: 20, sm: 3, md: 5, lg: 2 }}>
                  <WrapItem w={{ base: "100%", lg: "49%" }}>
                    <Box>
                      <Heading color="white">Kontakt</Heading>
                      <Text my={{ base: 3, md: 3, lg: 5 }} color="white">
                        Wir freuen uns, von Ihnen zu hören.
                      </Text>
                      <Box py={{ base: 0, sm: 5, md: 8, lg: 10 }}>
                        <VStack pl={0} spacing={3} alignItems="flex-start">
                          <Link href="tel.: 04421-42033">
                            <Button
                              size="md"
                              height={{ base: "56px", lg: "72px" }}
                              variant="ghost"
                              color="white"
                              _hover={{ border: "2px solid #aaa" }}
                              leftIcon={<MdPhone color="#ffffff" />}
                            >
                              Tel. 04421-42033
                            </Button>
                          </Link>
                          <Link href="mailto:info@veo-med.com">
                            <Button
                              size="md"
                              height={{ base: "56px", lg: "72px" }}
                              variant="ghost"
                              color="white"
                              _hover={{ border: "2px solid #aaa" }}
                              leftIcon={<MdEmail color="#ffffff" />}
                            >
                              info@veo-med.com
                            </Button>
                          </Link>
                          <FadeInAnimation
                            threshold={0.4}
                            delay={0.5}
                            duration={1.4}
                            initialX={0}
                            initialY={10}
                          >
                            <Link href="https://www.doctolib.de/praxis/wilhelmshaven/jade-klinik-dr-jann-voltmann-kollegen/booking/motive-categories?placeId=practice-349684&specialityId=1308&telehealth=false">
                              <Button
                                size="md"
                                height={{ base: "56px", lg: "72px" }}
                                variant="ghost"
                                color="white"
                                _hover={{ border: "2px solid #aaa" }}
                              >
                                Termine online
                              </Button>
                            </Link>
                          </FadeInAnimation>
                        </VStack>
                      </Box>
                    </Box>
                  </WrapItem>

                  <WrapItem w={{ base: "100%", lg: "49%" }}>
                    <Box w="100%" bg="white" borderRadius="lg">
                      <Box m={8} color="#0B0E3F">
                        <form onSubmit={handleSubmit(onSubmit)}>
                          <FormControl isInvalid={errors.name}>
                            <FormLabel mt={8}>Ihr Name</FormLabel>
                            <InputGroup borderColor="#E0E1E7">
                              <InputLeftElement
                                pointerEvents="none"
                                children={<BsPerson color="gray.800" />}
                              />
                              <Input
                                type="text"
                                size="md"
                                {...register("Name")}
                              />
                            </InputGroup>

                            <FormLabel mt={8}>e-Mail Adresse</FormLabel>
                            <InputGroup borderColor="#E0E1E7">
                              <InputLeftElement
                                pointerEvents="none"
                                children={<BsPerson color="gray.800" />}
                              />
                              <Input
                                type="text"
                                size="md"
                                {...register("EMail")}
                              />
                            </InputGroup>
                            <FormLabel mt={8}>Nachricht</FormLabel>
                            <Textarea
                              type="text"
                              size="md"
                              placeholder="Ihre Nachricht an uns"
                              name="nachricht"
                              {...register("Nachricht")}
                            />

                            <Button
                              mt={12}
                              variant="solid"
                              bg="#0D74FF"
                              color="white"
                              _hover={{}}
                              isLoading={isSubmitting}
                              type="submit"
                            >
                              Senden
                            </Button>
                          </FormControl>
                          <Box visibility={"hidden"}>
                            {isSubmitted &&
                              toast({
                                title: "Vielen Dank für Ihre Anmeldung!",
                                description:
                                  "Wir melden uns bei Ihnen mit einer Bestätigung.",
                                status: "success",
                                duration: 9000,
                                isClosable: true,
                              })}
                          </Box>
                        </form>
                      </Box>
                    </Box>
                  </WrapItem>
                </Wrap>
              </Box>
            </Box>
          </FadeInAnimation>
        </ImgTextModule>
      </Container>
      <Container variant="layoutContainer" mt={12}>
        <Flex>
          <Box
            bg="brand.gray.ulight"
            color="white"
            borderRadius="lg"
            w="100%"
            mx="auto"
            p={{ sm: 5, md: 5, lg: 16 }}
          ></Box>
        </Flex>
      </Container>
      <Container variant="layoutContainer" mt={12}>
        <TwoColumnGridBoxesAllgemein />
      </Container>
      <BannerBlepharo />
      <BannerHyaloron />
      <LogoHeadlineTeaserVeo
        headline="Ästhetik mit High-Tech"
        subheadline="Und Verantwortung"
      />
      <QuadTeaser />
    </Layout>
  )
}

export default Kontakt
